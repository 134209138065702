@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
#root {
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  z-index: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body, html {
  color: #000;
  background-color: #fff;
  font-family: Inter, --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  min-height: 100%;
  min-width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  @media only screen and (max-height: 424px) {
    font-size: 18px;
  }
}

p {
  margin: 15px auto;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 4px;
}

h2 {
  font-size: 22px;
  font-weight: 700;
}

h3 {
  font-size: 18px;
  font-weight: 700;
}
